import React from 'react';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { getUrlFromEnv } from '@/utils/getUrlFromEnvUtil';
import {
  COLOR,
  BREAKPOINT,
  MARGIN,
  ALIGN
} from '@latitude/core/utils/constants';
import { HorizontalRule } from '@latitude/horizontal-rule';
import { Link } from '@latitude/link';
import { Metadata } from '@latitude/metadata';
import { StickyNavigation } from '@latitude/sticky-navigation';
import { Box } from '@latitude/box';
import Layout from '@/components/layout';
import { SITE_URL, TITLE_SUFFIX } from '@/utils/constants';
import { Accordion } from '@latitude/accordion';
import { Hero } from '@latitude/hero';
import { Vertical } from '@latitude/spacing';
import { Lframe } from '@latitude/lframe';
import HowToApplyComponent from '@latitude/how-to-apply';
import { Heading3, Heading4, Heading6 } from '@latitude/heading';
import {
  MinimumMonthly,
  Flexible,
  OffersContainer,
  EqualMonthly,
  PlanTypes
} from '@latitude/interest-free-plans';
import { List, ListItem } from '@latitude/list';
import { InstalmentInterestFreeCalculator } from '@latitude/instalment-interest-free-calculator';
import { ImportantInformation } from '@latitude/important-information';
import { StaticImage } from 'gatsby-plugin-image';

import WhyChoose from '../gemvisa/_why-choose';
import RatesAndFees from '../gemvisa/_rates-and-fees';
import bgImageBbqg from './images/bbqg_hero_background_optimised.png';

const APPLY_URL = getUrlFromEnv('funnel-start-gemvisa-partners');

const title = 'Get that BBQ now on interest free with Latitude Gem Visa';
const description =
  'Shop interest free on BBQs, outdoor living, gas/wood heating, accessories and more.';

const CalculatorWidgetPage = ({ location }) => (
  <Layout location={location} noHeader noFooter>
    <Metadata
      title={`${title} | ${TITLE_SUFFIX}`}
      description={description}
      canonical={`${SITE_URL}${location.pathname}`}
    />

    <div
      css={`
        .accordion .accordion__button-fixed {
          position: relative;
          top: auto !important;
        }
      `}
    >
      <div
        css={`
          > div {
            padding-top: 0;
          }
        `}
      >
        <Box.Section backgroundColor={COLOR.GREY6}>
          <AnalyticsLocationProvider location="Equal Monthly Plan Calculator">
            <div
              className="py-3 px-4"
              css={`
                @media (max-width: 432px) {
                  &.px-4 {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                  }
                }
              `}
            >
              <InstalmentInterestFreeCalculator
                isGemVisa
                annualFee={0}
                establishmentFee={0}
                minimumAmount={500}
                monthlyFee={10.95}
                monthlyTerms={[50]}
                importantInfo={[
                  'These payments are for Equal monthly payment plans only and do not take into account any other purchases or balances.',
                  'The monthly, weekly and daily payments are approximate only.',
                  'Monthly payments are required, exact amount specified in the customer’s statement.',
                  'Minimum financed amount applies. See current promotions for terms and conditions.',
                  'Various plans only available during specific promotional periods.'
                ]}
              />
            </div>
          </AnalyticsLocationProvider>
        </Box.Section>
      </div>
    </div>
  </Layout>
);

export default CalculatorWidgetPage;
